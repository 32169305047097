<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          :src="appLogoImage"
          alt="logo"
          style="width: 200px;"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <template v-if="is_success === null">
              <b-card-title class="mb-1 text-center">
                    Harap tunggu!...
              </b-card-title>
          </template>
          <template v-else-if="is_success">
                <b-card-title class="mb-1 text-center">
                    Selamat akun kamu telah berhasil dibuat!
                </b-card-title>
                <b-card-text class="mb-2 text-center">
                    Silakan login dan lengkapi data
                </b-card-text>
                
                <p class="text-center mt-2">
                        <b-button
                            type="submit"
                            variant="primary"
                            block
                            id="btn-login"
                            @click="loginPage()"
                            >
                            Login
                        </b-button>
                </p>
          </template>
          <template v-else-if="!is_success">
                <b-card-title class="mb-1 text-center">
                    Failed.
                </b-card-title>
                
                <p class="text-center mt-2">
                        <b-button
                            type="submit"
                            variant="primary"
                            block
                            id="btn-login"
                            @click="loginPage()"
                            >
                            Login
                        </b-button>
                </p>
          </template>
          
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import axios from "@axios"
import { $themeConfig } from '@themeConfig'
// App Name
const { appName, appLogoImage } = $themeConfig.app

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      phoneNumber: '',
      userEmail: '',
      password: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
      end_time: null,
      is_success: null,
      appName,
      appLogoImage
    }
  },
  beforeMount() {
    let _ = this
    _.isActivatedSuccess()
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    loginPage() {
        let _ = this
        _.$router.push('/login')
    },
    isActivatedSuccess() {
        let _ = this
        axios.get('account/activate?token=' + _.$route.query.token)
        .then(resp => {
            console.log(resp)
            _.is_success = resp.data.success
        })
        .catch(err => {
            _.errors = err.response.data.message.errors ? err.response.data.message.errors[0].msg : err.response.data.message
        })
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
